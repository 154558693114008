import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  BrowserRouter,
  Routes
} from "react-router-dom";
import Accueil from "./pages/Accueil";
import Contact from "./pages/Contact";
import Services from "./pages/Services";
import Realisations from "./pages/Realisations";
import './App.css';
import { Layout } from "./Components/Layout";
import { NavigationBar } from "./Components/NavigationBar";
import "@fontsource/kanit";

class App extends Component {
  constructor(props){
    super(props);
  }
  render(){
    return (
      <div className="container-fluid">
        <React.Fragment>
        <Layout>
          <BrowserRouter>
          {/*<NavigationBar />*/}
          <Routes>
            <Route exact path="/" element={<Accueil/>} />
            <Route exact path="/nousjoindre" element={<Contact/>} />
            <Route exact path="/services" element={<Services/>} />
            <Route exact path="/realisations" element={<Realisations/>} />
          </Routes>
          </BrowserRouter>
        </Layout>
        </React.Fragment>
      </div>
    );
  }
}

export default App;
