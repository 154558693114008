import React, { Component } from "react";
import styles from "./Accueil.css";
import Logo from "../assets/Logo LES.png";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class Accueil extends Component{
    render(){
        return(
        <div class={"titre"}>
            <section>
            <h1>L.E.S. Rénovations</h1>
            <h2>Entrepreneur général</h2>
                <Row>
                <Col sm={4}>
                <img
                src={Logo}
                height="auto"
                width="80%"
                />
                </Col>
                <Col>
                <Row>
                <h3>Pour toutes demandes d'informations :</h3>
                <h>514-891-0766</h>
                <a href="mailto:les.renovations.inc@gmail.com">les.renovations.inc@gmail.com</a>
                <p>R.B.Q.: 5826-3070-01</p>
                </Row>
                </Col>
                </Row>
            </section>
        </div>
        );
    }
}
export default Accueil;