import React, { Component } from "react";
import styles from "./Contact.css";

class Contact extends Component{
    render(){
        return(
            <div id="Nous-Joindre" className={styles["Nous-Joindre"]}>
            <section id="Coord">
            <h2>Voici les informations pour nous joindre</h2>
            <hr/>
            <p>Site internet :<a href="http://clubnautiquedemezy.org">clubnautiquedemezy.org</a></p>
            <p>Boîte vocale : 450-274-1136</p>
            <p>Adresse : 925 boul Marie-Victorin, Boucherville</p>
            <p>C.P. : 413</p>
            </section>
        </div>
        );
    }
}
export default Contact;