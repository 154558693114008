import React, { Component } from "react";
import styles from "./Services.css";

class Services extends Component{
    render(){
        return(
        <div>
            <section>
                <h1>Services offerts</h1>
            </section>
        </div>
        );
    }
}
export default Services;