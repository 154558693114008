import React, { Component } from "react";
import styles from "./Realisations.css";

class Realisations extends Component{
    render(){
        return(
        <div id="Main-page" className={styles["Main-page"]}>
            <section id="Title">
                <h1>Réalisation</h1>
            </section>
        </div>
        );
    }
}
export default Realisations;